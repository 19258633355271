<template lang="pug">
  div.edit-name-wrapper
    v-dialog(:value="showEditNameModal" max-width="468")
      v-card
        v-card-title.headline.justify-space-between
          | Change Course Name
        v-card-text
          v-text-field(
            v-model="editName"
            label='Name'
            data-test="course-name-input"
          )
          v-btn(color="primary" outlined @click="hideModal").ml-auto.mr-3 Cancel
          v-btn(data-test="course-name-save-btn" color="primary" depressed @click="$emit('save', editName)") Save

    v-tooltip(
      top
      :disabled="!courseName"
    )
      template(v-slot:activator="{ on }")
        p(v-on="on" @click="showModal" data-test="course-name-trigger").course-name {{ courseName }}
      span {{ courseName }}
</template>


<script>
  export default {
    props: {
      courseName: {
        type: String,
      }
    },
    data: () => ({
      editName: '',
      showEditNameModal: false,
    }),
    created() {
      this.editName = this.courseName
    },
    methods: {
      hideModal() {
        this.showEditNameModal = false
      },
      showModal() {
        this.showEditNameModal = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .edit-name-wrapper{
    display: flex;
    align-items: center;

    .course-name{
      display: inline-block;
      max-width: 125px;
      width: 100%;
      min-height: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }
  }


</style>